<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-table-list" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" class="mt-4" @click="gpath_career_typeAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
              </b-col>
              <b-col class="col-5 col-sm-3 col-md-2 col-lg-3 mb-2">
                <VueJsonToCsv :json-data="gpath_career_typeObjList" csv-title="gpath_career_type_list" v-if="gpath_career_typeObjList && gpath_career_typeObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_career_typeObjList && gpath_career_typeObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_career_typeObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(action)="data">
                    <b-button variant="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_career_typeEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathCareertypeDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>

                  <template v-slot:cell(career_type)="data">
                    {{ data.item.name == data.item.name2 ? "Single" : "Combo" }}<br>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathCareertypeAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <GPathCareertypeAdd :propOpenedInModal="true" @emitCloseGPathCareertypeAddModal="closeGPathCareertypeAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_typeAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareertypeAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathCareertypeEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <GPathCareertypeEdit :propOpenedInModal="true" :propGPathCareertypeObj="gpath_career_typeEditObj" @emitCloseGPathCareertypeEditModal="closeGPathCareertypeEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_typeEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareertypeEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathCareertypeDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathCareertypeDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_career_typeDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathCareertypes } from "../../../FackApi/api/GPathCareertype"
import GPathCareertypeAdd from "./GPathCareertypeAdd"
import GPathCareertypeEdit from "./GPathCareertypeEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathCareertypeList",
  components: {
    GPathCareertypeAdd,
    GPathCareertypeEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_career_type_list",
      cvModuleFile: "gpath_career_type",
      cvModulePrefix: "GPCT",
      cvCardTitle: "GPath Career Types",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPath Career Type",
      cvAddModalHeader: "Add GPath Career Type",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPath Career Type List",
      showModelGPathCareertypeAdd: false,
      showModelGPathCareertypeEdit: false,
      showModelGPathCareertypeDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "action", key: "action", class: "text-left align-text-top", sortable: true },
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "name2", key: "name2", class: "text-left align-text-top", sortable: true },
        { label: "career type", key: "career_type", class: "text-left align-text-top", sortable: true },
        { label: "title", key: "title", class: "text-left align-text-top", sortable: true },
        { label: "detail", key: "detail", class: "text-left align-text-top", sortable: true },
        { label: "url", key: "url", class: "text-left align-text-top", sortable: true }
      ],
      gpath_career_typeObjList: null,
      gpath_career_typeEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_career_typeList()
  },
  methods: {
    /**
     * gpath_career_typeList
     */
    async gpath_career_typeList () {
      try {
        let gpathCareerTypeListResp = await GPathCareertypes.gpath_career_typeList(this, { name: this.$route.params.name })
        if (gpathCareerTypeListResp.resp_status) {
          this.gpath_career_typeObjList = gpathCareerTypeListResp.resp_data.data
          this.totalRows = gpathCareerTypeListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathCareerTypeListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_career_typeList() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_typeAdd
     */
    gpath_career_typeAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_type_add")
        }
        else {
          this.showModelGPathCareertypeAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_typeAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_typeEdit
     */
    gpath_career_typeEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_type_edit/" + this.gpath_career_typeEditObj.gpct_id)
        }
        else {
          this.gpath_career_typeEditObj = item
          this.showModelGPathCareertypeEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_typeEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathCareertypeDeleteDialog
     */
    showGPathCareertypeDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathCareertypeDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathCareertypeDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_typeDelete
     */
    async gpath_career_typeDelete () {
      try {
        let gpathCareerTypeDelResp = await GPathCareertypes.gpath_career_typeDelete(this, this.delObj.gpct_id)
        await ApiResponse.responseMessageDisplay(this, gpathCareerTypeDelResp)

        if (gpathCareerTypeDelResp && !gpathCareerTypeDelResp) {
          this.showModelGPathCareertypeDelete = false
          return false
        }

        let index = this.gpath_career_typeObjList.indexOf(this.delObj)
        this.gpath_career_typeObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathCareertypeDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_typeDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareertypeAddModal
     */
    closeGPathCareertypeAddModal (gpathCareerTypeAddData) {
      try {
        if (gpathCareerTypeAddData) {
          if (this.gpath_career_typeObjList && this.gpath_career_typeObjList.length >= 1) {
            let gpathCareerTypeObjLength = this.gpath_career_typeObjList.length
            let lastId = this.gpath_career_typeObjList[gpathCareerTypeObjLength - 1]["id"]
            gpathCareerTypeAddData.id = lastId + 1
          }
          else {
            this.gpath_career_typeObjList = []
            gpathCareerTypeAddData.id = 11111
          }

          gpathCareerTypeAddData.created_on = moment()
          this.gpath_career_typeObjList.unshift(gpathCareerTypeAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathCareertypeAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_career_typeAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareertypeEditModal
     */
    closeGPathCareertypeEditModal () {
      try {
        this.showModelGPathCareertypeEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathCareertypeEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
